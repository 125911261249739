
.navbar-customclass {
  background-color: #032742;
}

.navbar {
  padding: 0.2rem 0.5rem !important;
  font-weight: 500;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.8) !important;
}
.navbar-brand a {
  width: 400px !important;
}

.navbar a {
  margin-right: 15px !important;
  font-size: 18px;
  text-decoration: none !important;
    
}

.active-link {
  color: white !important;
  border-bottom-color: white;
  border-bottom-width: 1.5px;
  border-bottom-style: solid;
}

.inactive-link {
  color: #e0e0e0 !important;
  border-bottom-color: white;
  border-bottom-width: 0px;
  border-bottom-style: solid;
}


a.pc-linkbutton  {
  border-style: solid;
  border-width: 1px;
  border-radius: 3px;
  text-decoration: none;
  min-width: 140px;
  display: inline-block;
  margin-top: 5px;
  padding: 10px 5px 10px 5px;
  font-size: 15px;
  background-color: #08304d;
  color: whitesmoke;
  }

button.pc-button  {
  border-style: solid;
  border-width: 1px;
  border-radius: 3px;
  text-decoration: none;
  min-width: 140px;
  display: inline-block;
  margin-top: 5px;
  padding: 10px 5px 10px 5px;
  font-size: 15px;
  background-color: #08304d;
  color: whitesmoke;
}